










// Components
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    GeneralLayout: () => getComponent('common/GeneralLayout'),
    BugsnagErrorsChart: () => getComponent('bugsnag/BugsnagErrorsChart'),
    BugsnagDetailsTable: () => getComponent('bugsnag/BugsnagDetailsTable'),
  },
})
export default class Bugsnag extends Vue {}
